import { useFontAwesomeIconsEffect } from '@/hooks/useFontAwesomeIconsEffect/useFontAwesomeIconsEffect';
import { LandingPageData } from '@/lib/butter-types';
import { getContent } from '@/lib/butter/getContent/getContent';
import { getPage } from '@/lib/butter/getPage/getPage';
import { listBlogPosts } from '@/lib/butter/listBlogPosts/listBlogPosts';
import { withDefaultStaticProps } from '@/lib/composable';
import { getProducts } from '@/lib/products';
import { ContactSection } from '@/ui/contact-section/contact-section';
import { DigitalSection } from '@/ui/digital-section/digital-section';
import { GrimmeDataPerRegionProvider } from '@/ui/grimme-data-per-region/provider';
import { Hero } from '@/ui/hero';
import { Layout } from '@/ui/layout/layout';
import { NewsSection } from '@/ui/news-section/news-section';
import { PartnersSection } from '@/ui/partners-section/partners-section';
import { ProductDetailsPreview } from '@/ui/product-details-preview/product-details-preview';
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_LOCALE,
  NEWS_CATEGORY,
  parseCtaSectionButtons,
} from '@/utils';
import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { Container } from '@mui/material';
import { NextSeo } from 'next-seo';
import Separator from '@grimme/ui-components/separator';
import { Suspense } from 'react';
import Image from 'next/image';
import Button from '@grimme/ui-components/button';
import Link from 'next/link';

export const getStaticProps = async ({
  locale,
  preview = false,
}: GetStaticPropsContext) => {
  return await withDefaultStaticProps({
    revalidate: 300,
    revalidateOnError: 10,
    locale: locale || DEFAULT_LOCALE,
    preview: preview || false,
    props: async ({ includeIcons }) => {
      const landingPageData = await getPage<LandingPageData>(
        locale || DEFAULT_LOCALE,
        preview,
        'grimme-landing-page',
      );

      const heroV2ComponentIcons = (
        landingPageData?.fields?.hero_v2?.sections || []
      ).map((section) => ({
        prefix: section.label_icon_type,
        iconName: section.label_icon,
      })) as IconLookup[];
      includeIcons(heroV2ComponentIcons);

      const heroV2NewsSection =
        landingPageData?.fields?.hero_v2?.sections?.find(
          (section) => section.section_type === 'news',
        );

      if (heroV2NewsSection) {
        const generalBlogPosts = await listBlogPosts({
          locale,
          preview,
          pageNumber: 1,
          pageSize: 1,
        });
        const featuredBlogPosts = generalBlogPosts.data[0]; // Picking latest blog post

        if (featuredBlogPosts) {
          heroV2NewsSection.image = featuredBlogPosts.featured_image!;
          heroV2NewsSection.title = featuredBlogPosts.title;
          heroV2NewsSection.subtitle = featuredBlogPosts.summary;
          heroV2NewsSection.secondary_button_link = `news/${featuredBlogPosts.slug}`;
        }
      }

      return {
        preview,
        landingPageData,
        newsFeed: await listBlogPosts({
          locale,
          preview,
          pageNumber: 1,
          pageSize: 3,
          categorySlug: NEWS_CATEGORY,
        }),
        productsDetails: await Promise.all(
          landingPageData.fields.product_details_page_preview.categories.map(
            async (category) => ({
              products: await getProducts(
                locale || DEFAULT_LOCALE,
                category.slugs.split(','),
              ),
              category: category.name,
            }),
          ),
        ),
        /*
        IMPORTANT: Calling regions based contacts ways only from DE locale
        because it will be our single source of truth for this kind of data.
        */
        contactsPerRegionData: await getContent(
          DEFAULT_COUNTRY_CODE.toLowerCase(),
          false,
          ['grimme_contacts_per_region'],
        ),
      };
    },
  });
};

export default function IndexPage(
  props: InferGetStaticPropsType<typeof getStaticProps>,
) {
  const {
    contactsPerRegionData,
    globalSettings,
    icons,
    landingPageData,
    newsFeed,
    preview,
    productsDetails,
  } = props;
  useFontAwesomeIconsEffect(icons);

  //Buttons for the two CTA sections
  const firstCtaButtons = parseCtaSectionButtons(
    (landingPageData.fields.ctas_section &&
      landingPageData.fields.ctas_section.buttons) ||
      [],
  );

  const secondCtaButtons = parseCtaSectionButtons(
    (landingPageData.fields.grimme_group &&
      landingPageData.fields.grimme_group.buttons) ||
      [],
  );

  return (
    <Suspense>
      <GrimmeDataPerRegionProvider
        value={{ contactsRaw: contactsPerRegionData }}
      >
        <Layout globalSettings={globalSettings} preview={preview}>
          <NextSeo
            description={landingPageData.fields.seo.description}
            nofollow={landingPageData.fields.seo.no_index}
            noindex={landingPageData.fields.seo.no_index}
            title={landingPageData.fields.seo.title}
          />

          <Hero {...landingPageData.fields.hero_v2} />

          <div className="py-6">
            <Container className="text-center my-20">
              <h2 className="mb-2">
                {landingPageData.fields.seo_section_components_library.title}
              </h2>
              <p className="mb-4">
                {landingPageData.fields.seo_section_components_library.subtitle}
              </p>
              <Separator className="max-w-10 mx-auto" thickness="thicker" />
            </Container>
          </div>

          <div className="py-6">
            <ProductDetailsPreview
              productsDetails={productsDetails}
              {...landingPageData.fields.product_details_page_preview}
            />
          </div>

          <div className="py-14 bg-white">
            <DigitalSection {...landingPageData.fields.grimme_digital} />
          </div>

          {/* This is the unwrapped CTA section component. If we introduce it anywhere else we should create a wrapper and use it here and below */}
          <div className="w-full py-32 px-6 relative text-white text-center grid place-content-center">
            <div className="absolute inset-0 opacity-60 bg-grey-500 z-20" />
            <div className="absolute top-0 left-0 right-0 bottom-0">
              <Image
                fill
                objectFit="cover"
                src={landingPageData.fields.ctas_section.background_image}
                alt={landingPageData.fields.ctas_section.title}
              />
            </div>
            <div className="relative max-w-[800px] flex flex-col gap-4 z-30">
              <span className="g-typography-h2">
                {landingPageData.fields.ctas_section.title}
              </span>
              <span className="g-typography-body1">
                {landingPageData.fields.ctas_section.description}
              </span>
              <div className="flex flex-wrap justify-center gap-4">
                {firstCtaButtons.map((button) => (
                  <Button key={button.id} variant="outline-negative">
                    <Link href={button.props.href as string}>
                      {button.label}
                    </Link>
                  </Button>
                ))}
              </div>
            </div>
          </div>

          <div className="py-14">
            <NewsSection
              {...landingPageData.fields.news}
              feed={newsFeed.data}
            />
          </div>

          <div className="py-14">
            <PartnersSection {...landingPageData.fields.grimme_partners} />
          </div>

          <div className="w-full py-32 px-6 relative text-white text-center grid place-content-center">
            <div className="absolute inset-0 opacity-60 bg-grey-500 z-20" />
            <div className="absolute top-0 left-0 right-0 bottom-0">
              <Image
                fill
                objectFit="cover"
                src={landingPageData.fields.grimme_group.background_image}
                alt={landingPageData.fields.grimme_group.title}
              />
            </div>
            <div className="relative max-w-[800px] flex flex-col gap-4 z-30">
              <span className="g-typography-h2">
                {landingPageData.fields.grimme_group.title}
              </span>
              <span className="g-typography-body1">
                {landingPageData.fields.grimme_group.description}
              </span>
              <div className="flex flex-wrap justify-center gap-4">
                {secondCtaButtons.map((button) => (
                  <Button key={button.id} variant="outline-negative">
                    <Link href={button.props.href as string}>
                      {button.label}
                    </Link>
                  </Button>
                ))}
              </div>
            </div>
          </div>

          <div className="py-14">
            <ContactSection {...landingPageData.fields.contact_section} />
          </div>
        </Layout>
      </GrimmeDataPerRegionProvider>
    </Suspense>
  );
}
